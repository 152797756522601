<template>
  <v-dialog v-model="showDialog" fullscreen hide-overlay transition="dialog-bottom-transition" persistent>
    <v-card elevation="0" tile>
      <v-card-title class="pa-1" v-bind:style="{
        background: this.$vuetify.breakpoint.smAndDown ? '#FF6969' : '#2a5298'
      }">
        <v-row no-gutters>
          <v-col>
            <v-btn v-bind:class="this.$vuetify.breakpoint.smAndDown? 'pa-0 px-0 mx-0' : ''" class="pa-0 ma-0 text-white"
              text @click="closeDialog" :color="this.$vuetify.breakpoint.smAndDown? 'white' : 'warning'">
              <v-icon v-bind:class="this.$vuetify.breakpoint.smAndDown? 'pa-0 px-0 mx-0' : ''" size="40px">
                mdi-chevron-left
              </v-icon>
              <span v-if="!this.$vuetify.breakpoint.smAndDown">{{$t('generic.lang_prev')}}</span>
            </v-btn>
          </v-col>
          <v-col align="center">
            <p class="text-center text-white ma-0" style="width:100%;">{{ $t('generic.lang_endShift') }}</p>
          </v-col>
          <v-col align="right">
            <v-btn class="pa-0 ma-0 text-white" text>
              <span>{{ user.name }} {{ user.last_name }}</span>
              <v-icon size="20px" color="white">mdi-lock</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>
      <v-stepper v-model="step" class="h-100">
        <v-stepper-header>
          <!-- x report -->
          <v-stepper-step step="1" :complete="step > 1">{{ $t('generic.lang_xReport') }}</v-stepper-step>

          <v-divider></v-divider>
          <!-- cash drop -->
          <v-stepper-step step="2" :complete="step > 2">{{ $t('generic.lang_cashDrop') }}</v-stepper-step>

          <v-divider></v-divider>
          <!-- deposit / expenditure -->
          <v-stepper-step step="3" :complete="step > 3">{{ $t('generic.lang_deposit_display') }}</v-stepper-step>
          <v-divider></v-divider>
          <!-- close the cash register -->
          <v-stepper-step step="4">{{ $t('generic.lang_closeCashier') }}</v-stepper-step>
        </v-stepper-header>
        <v-divider class="pa-0 ma-0" />

        <v-stepper-items class="pa-0">
          <v-stepper-content :step="2" class="pa-0">
            <v-card-text class="">
              <v-container class="pa-0">
                <v-row>
                  <v-col v-if="!$vuetify.breakpoint.smAndDown" cols="12" sm="6" md="6" lg="7">
                    <v-row>
                      <v-col v-if="countingMandatory && !validCountingInput" cols="12">
                        <v-alert type="warning" outlined dense class="mb-0">
                          {{ $t('accounting.lang_countingCashMandatory') }}
                        </v-alert>
                      </v-col>
                      <v-col v-show="!$vuetify.breakpoint.smAndDown" cols="4" sm="6" md="6" lg="4" xl="4"
                        v-for="(coin) in currency[currencyIMG].muenzen" :key="coin.value">
                        <v-row no-gutters align="end">
                          <v-col cols="6" align="end" align-self="center" class="pr-1">
                            <v-img :src="coin.image" width="50%" contain />
                          </v-col>
                          <v-col cols="6">
                            <v-text-field min="0" v-model="coin.inValue" height="50px" type="number"
                              @focus="remember($event,coin)" clearable :disabled="!usingCounting" @input="update(null)">
                            </v-text-field>
                          </v-col>
                        </v-row>
                      </v-col>
                      <v-col v-show="!$vuetify.breakpoint.smAndDown" cols="4" sm="6" md="6" lg="4" xl="4"
                        v-for="(paper)  in currency[currencyIMG].scheine" :key="paper.value">
                        <v-row no-gutters>
                          <v-col class="pr-1" cols="6" align="end" align-self="center">
                            <v-img :src="paper.image" width="100%" contain />
                          </v-col>
                          <v-col cols="6">
                            <v-text-field min="0" @focus="remember($event,paper)" v-model="paper.inValue" height="50px"
                              type="number" clearable :disabled="!usingCounting" @input="update(null)">
                            </v-text-field>
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="12" sm="6" md="6" lg="5" :class="{'mb-12':$vuetify.breakpoint.smAndDown}">
                    <v-card elevation="0" height="70%" width="100%">

                      <v-card-title class="pa-0">
                        <v-row>
                          <v-col cols="12" align="right" class="text-right">
                            <v-switch :label="$t('generic.lang_countCash')" v-model="usingCounting" hide-details
                              :disabled="countingMandatory">
                            </v-switch>
                          </v-col>

                          <v-col cols="12">
                            <h5 class="mb-0">
                              <strong>{{ $t('erp.lang_DailyAnfangsbestand') }} </strong>{{ startCash|currency }}
                            </h5>
                          </v-col>
                          <v-col v-if="!hideSystemCountedCash" cols="12" class="pb-0">
                            <v-currency-field
                              :locale="$store.getters['settings/getSettingValue']('geoloc_systemcountry')"
                              :label="$t('erp.lang_cashEndTotal')" outlined required @focus="remember($event,null)"
                              v-model="countedEndCash" append-icon="book" :disabled="loadingCashbookValue"
                              :loading="loadingCashbookValue" @click:append="getCashFromCashBook"
                              :readonly="usingCounting"></v-currency-field>
                          </v-col>
                          <v-col v-else cols="12">
                            <h5 class="mb-0 text-capitalize">{{$t('generic.lang_cash')}} {{total|currency}}</h5>
                          </v-col>
                        </v-row>
                      </v-card-title>
                      <v-card-text class="pa-0 ma-0">
                        <CashoutNumpad @change="update" @del="del"
                          :button-style="($vuetify.breakpoint.smAndDown)?{height:60,padding:5}:{height:75,padding:7}"
                          double-zero />
                      </v-card-text>
                    </v-card>
                  </v-col>
                </v-row>
                <v-row v-if="!$vuetify.breakpoint.smAndDown">
                  <v-col large cols="12" align="end">
                    <v-btn color="primary" @click="step=3" :disabled="!validCountingInput">{{ $t('generic.lang_next') }}
                      <v-icon>mdi-chevron-right</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-container>
              <v-bottom-navigation value="1" v-if="$vuetify.breakpoint.smAndDown" fixed horizontal>
                <v-row align="center">
                  <v-col cols="12" align="end">
                    <v-btn color="primary" class="ma-0" text @click="step=3" :disabled="!validCountingInput">
                      <v-icon>mdi-chevron-right</v-icon>
                      {{ $t('generic.lang_next') }}
                    </v-btn>
                  </v-col>
                </v-row>
              </v-bottom-navigation>
            </v-card-text>
          </v-stepper-content>
          <v-stepper-content :step="3" class="pa-2 overflow-y-auto">
            <v-card v-if="!disableCashbookEntries" outlined elevation="0" class="my-1 pa-2">
              <v-row>
                <v-col  cols="12">
                  <h5 v-if="!hideSystemCountedCash" class="mr-6">
                    {{ countedEndCash |currency }} = {{ $t('erp.lang_DailyAnfangsbestand') }} {{ startCash| currency }}
                    + {{ $t('accounting.lang_turnover') }}
                    {{ (countedEndCash - startCash)|currency }}
                  </h5>
                  <!-- <h5 v-else class="mr-6">
                    {{ total |currency }} = {{ $t('erp.lang_DailyAnfangsbestand') }} {{ startCash| currency }}
                    + {{ $t('accounting.lang_turnover') }}
                    {{ (total - startCash)|currency }}
                  </h5> -->
                </v-col>
                <v-col cols="12" align="end">
                  <v-btn v-if="!hideSystemCountedCash" color="primary" class="ma-0 ma-1" height="40" @click="getCashFromCashBook"
                    :loading="loadingCashbookValue" :small="$vuetify.breakpoint.smAndDown">
                    {{ $t('generic.lang_recalculate') }}
                  </v-btn>
                  <v-btn color="success" class="ma-0 ma-1" height="40" @click="showCreateEntryDialog = true"
                    :small="$vuetify.breakpoint.smAndDown">
                    {{ $t('generic.lang_newCashbookEntry') }}
                  </v-btn>
                </v-col>
              </v-row>
            </v-card>
            <v-card v-if="!disableCashbookEntries" outlined elevation="0" class="pa-0">
              <v-card-title class="pa-1" style="font-size:100%;">
                {{ $t('generic.lang_cashBookEntriesMonth') }} {{ cashbookActiveMonth }}
              </v-card-title>
              <v-divider class="ma-0" />
              <v-progress-linear v-if="cashBookLoading" indeterminate color="primary"></v-progress-linear>
              <v-card-text class="pa-0" style="height:420px;overflow:auto;">
                <b-table ref="cashOverviewTable" striped hover responsive :items="items" :fields="fields">
                  <template v-slot:cell(AKTION)="row">
                    <!-- We use @click.stop here to prevent a 'row-clicked' event from also happening -->
                    <v-btn v-if="row.item.CLOSED < 1" size="sm" icon text color="primary"
                      @click="editEntryDialog(row.item.ENTRYUUID)">
                      <v-icon>edit</v-icon>
                    </v-btn>
                    <v-btn v-if="row.item.CLOSED < 1" icon text color="error" @click="deleteEntry(row.item.ENTRYUUID)">
                      <v-icon medium>delete</v-icon>
                    </v-btn>

                  </template>
                </b-table>
              </v-card-text>
            </v-card>
            <v-row>
              <v-col cols="12" align="end">
                <v-btn :small="this.$vuetify.breakpoint.smAndDown" class="ma-1" color="primary" @click="step=2"
                  :loading="loading" :disabled="loading" outlined>
                  <v-icon>
                    mdi-chevron-left
                  </v-icon>
                  {{ $t('generic.lang_prev') }}
                </v-btn>
                <v-btn class="ma-1" :small="this.$vuetify.breakpoint.smAndDown"
                  :color="this.$vuetify.breakpoint.smAndDown? '#FF6969' : 'success'" text
                  @click="checkCloseShift(false)" :loading="loading" :disabled="loading" outlined>
                  {{ $t('generic.lang_endShift') }}
                </v-btn>
                <v-btn v-bind:class="this.$vuetify.breakpoint.smAndDown? 'text-white' : ''"
                  :elevation="this.$vuetify.breakpoint.smAndDown? 0 : 2" :small="this.$vuetify.breakpoint.smAndDown"
                  class="ma-1" :color="this.$vuetify.breakpoint.smAndDown? '#FF6969' : 'success'"
                  @click="checkCloseShift(true)" :loading="loading" :disabled="loading">
                  {{ $t('generic.lang_endShift') }} + {{ $t('accounting.lang_Printdaily') }}
                </v-btn>
              </v-col>
            </v-row>
          </v-stepper-content>
          <v-stepper-content :step="4" class="pa-2">
            <v-row justify="center">
              <v-col cols="12" align-self="center" align="center">
                <v-icon class="mt-16" size="100" color="primary">
                  mdi-information-outline
                </v-icon>
              </v-col>

              <v-col cols="12" align-self="center" align="center">
                <h1 class="text--accent-1">{{ $t('generic.lang_ready') }}</h1>
              </v-col>
              <v-col cols="12" align-self="center" align="center">
                <v-btn color="primary" @click="closeDialog">
                  {{ $t('generic.lang_closeCashier') }}
                </v-btn>
              </v-col>
            </v-row>
          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>

    </v-card>

    <!-- ERROR DIALOG -->
    <v-dialog :value="errorMessage.length > 0" persistent max-width="300">
      <v-card>
        <v-card-title>{{ $t('generic.lang_info') }}</v-card-title>
        <v-card-text class="text-center">{{ errorMessage }}</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text color="primary" @click="closeErrorDialog">{{ $t('generic.lang_next') }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogEdit" persistent max-width="600px">
      <v-card>
        <v-card-title>
          <span class="headline">{{ $t('accounting.lang_editCashBookEntry') }}</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="6">
                <v-select outlined :items="cashierIDs" v-model="cashierIDEditVal" :label="$t('generic.lang_cashierID')"
                  disabled readonly required></v-select>
              </v-col>
              <v-col cols="12" sm="6">
                <v-select outlined item-text="text" item-value="value"
                  :items="[{text: $t('accounting.lang_income'),value: 1},{text: $t('accounting.lang_expense'),value: 0}]"
                  @change="editEntryTotal=0" v-model="editEntryType" :label="$t('accounting.lang_invoiceType')"
                  required></v-select>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" sm="4">
                <v-select outlined item-text="text" item-value="value" v-model="editEntryAccount"
                  :rules="[v=>!!v||$t('generic.lang_requiredField')]"
                  :label="$t('accounting.langAccountingAccount') + ':'" v-if="accounts && accounts != null"
                  :items="accounts"></v-select>

              </v-col>
              <v-col cols="12" sm="4">
                <v-select :items="itemTaxSelect" :label="$t('erp.lang_tax_value')" item-text="name" item-value="tax"
                  outlined v-model="editEntryTax"></v-select>
              </v-col>
              <v-col cols="12" sm="4">
                <v-currency-field
                  :label="$t('accounting.lang_grossAmountIn') +' '+ $store.getters['settings/currencyData']('symbol')"
                  outlined required v-model="editEntryTotal" @focus="showTouchKeyboard"
                  :data-layout="KEYBOARD.KEYSETS.NUMERIC" :min="0" :auto-decimal-mode="true"
                  :max="editEntryType===0?countedEndCash:null"
                  :locale="$store.getters['settings/getSettingValue']('geoloc_systemcountry')" :allow-negative="false"
                  :rules="[rules.checkTotalWithEndCredit,]"></v-currency-field>
              </v-col>
            </v-row>


            <v-row>
              <v-col cols="12" sm="6">
                <v-text-field outlined @focus="showTouchKeyboard" :data-layout="KEYBOARD.KEYSETS.NORMAL"
                  v-model="editEntryBelNo" :label="$t('accounting.lang_invoiceNo')"></v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <date-picker :label="$t('accounting.lang_receiptDate')"
                  :rules="[v=>!!v|| $t('generic.lang_requiredField')]" v-model="editEntryBelDate" clearable />
              </v-col>

            </v-row>

            <v-row>
              <v-col cols="12" sm="12">
                <v-textarea outlined @focus="showTouchKeyboard" :data-layout="KEYBOARD.KEYSETS.NORMAL"
                  :label="$t('generic.lang_Freitext')" v-model="editEntryFreetext"></v-textarea>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="dialogEdit = false">{{ $t('generic.lang_close') }}</v-btn>
          <v-btn color="blue darken-1" :disabled="editEntryAccount < 1||!editEntryBelDate" text
            @click="editCashbookEntry()">
            {{ $t('generic.lang_edit') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- DIALOGS -->
    <FiscalDeviceExportData :show-dialog="showTSEExportDialog" ref="tseExportDialog" @exportDone="finishClosingShift">
    </FiscalDeviceExportData>
    <CashbookCreateEntryDialog :taxes="itemTaxSelect" :accounts="accounts" :cashier-i-ds="cashierIDs"
      :end-credit="countedEndCash" :show-dialog="showCreateEntryDialog" @closeDialog="showCreateEntryDialog = false"
      @entryCreated="closeCreateEntryDialog"></CashbookCreateEntryDialog>
  </v-dialog>
</template>

<script>
import {ENDPOINTS} from "@/config";
import {Events} from "@/plugins/events";
import {mapGetters, mapState} from "vuex";
import CashoutNumpad from "@/components/pos/cashout/CashoutNumpad";
import FiscalDeviceExportData from "@/components/common/fiscalDevice/FiscalDeviceExportData";
import mixin from "@/mixins/KeyboardMixIns";
import CashbookCreateEntryDialog from "@/components/accounting/CashbookCreateEntryDialog";
import DatePicker from "@/components/common/datepicker";
import {printDataFromPrinter} from "../../plugins/printing/printerController";

export default {
  name: "EndShiftCounting",

  components: {
    CashbookCreateEntryDialog,
    FiscalDeviceExportData,
    CashoutNumpad,
    DatePicker
  },

  props: {
    showDialog: {
      type: Boolean,
      default: false,
    },
    dontRedirect: Boolean,
  },
  mixins: [mixin],
  data() {
    return {
      rules:{
        checkTotalWithEndCredit:(v)=>{

          let val =(v+'').replaceAll('.','')
          val = Number(val.replace(',','.'))

          if(isNaN(val)){
            return this.$t('generic.lang_pleaseEnterValidNumber')
          }

          if(this.createEntryType===1){
            return  !!val||this.$t('generic.lang_requiredField');
          }
          if(Number(val) > Number(this.countedEndCash)){
            return this.$t('generic.lang_shouldBeLessThanTheEndingBalance')
          }
          return !!val || this.$t('generic.lang_requiredField');
        },
      },
      open_cashDrawer: false,
      disable_print_interim_report: false,
      step: 2,
      lastFocus: null,
      startCash: 0.00,
      countedEndCash: 0.00,
      countedEndCashOrigin: 0.00,
      usingCounting: false,
      loading: false,
      loadingCashbookValue: false,
      errorMessage: "",
      showTSEExportDialog: false,
      tseExportData: "",
      shiftUUID: "",
      cashbookEntryUUID: "",
      currency: {
        euro: {
          muenzen: [
            {
              value: 0.01,
              image: require("@/assets/images/currency/euro/1cents.png"),
              inValue: "",
            },
            {
              value: 0.02,
              image: require("@/assets/images/currency/euro/2cents.png"),
              inValue: "",
            },
            {
              value: 0.05,
              image: require("@/assets/images/currency/euro/5cents.png"),
              inValue: "",
            },
            {
              value: 0.1,
              image: require("@/assets/images/currency/euro/10cents.png"),
              inValue: "",
            },
            {
              value: 0.2,
              image: require("@/assets/images/currency/euro/20cents.png"),
              inValue: "",
            },
            {
              value: 0.5,
              image: require("@/assets/images/currency/euro/50cents.png"),
              inValue: "",
            },
            {
              value: 1,
              image: require("@/assets/images/currency/euro/1.png"),
              inValue: "",
            },
            {
              value: 2,
              image: require("@/assets/images/currency/euro/2.png"),
              inValue: "",
            },
          ],
          scheine: [
            {
              value: 5,
              image: require("@/assets/images/currency/euro/5.png"),
              inValue: "",
            },
            {
              value: 10,
              image: require("@/assets/images/currency/euro/10.png"),
              inValue: "",
            },
            {
              value: 20,
              image: require("@/assets/images/currency/euro/20.png"),
              inValue: "",
            },
            {
              value: 50,
              image: require("@/assets/images/currency/euro/50.png"),
              inValue: "",
            },
            {
              value: 100,
              image: require("@/assets/images/currency/euro/100.png"),
              inValue: "",
            }
          ]
        },
        mad: {
          muenzen: [
            {
              value: 0.5,
              image: require("@/assets/images/currency/dirham/0dot5.png"),
              inValue: "",
            },
            {
              value: 1,
              image: require("@/assets/images/currency/dirham/1.png"),
              inValue: "",
            },
            {
              value: 2,
              image: require("@/assets/images/currency/dirham/2.png"),
              inValue: "",
            },
            {
              value: 5,
              image: require("@/assets/images/currency/dirham/5.png"),
              inValue: "",
            },
            {
              value: 10,
              image: require("@/assets/images/currency/dirham/10.png"),
              inValue: "",
            },
          ],
          scheine: [
            {
              value: 20,
              image: require("@/assets/images/currency/dirham/20.png"),
              inValue: "",
            },
            {
              value: 50,
              image: require("@/assets/images/currency/dirham/50.png"),
              inValue: "",
            },
            {
              value: 100,
              image: require("@/assets/images/currency/dirham/100.png"),
              inValue: "",
            },
            {
              value: 200,
              image: require("@/assets/images/currency/dirham/200.png"),
              inValue: "",
            }
          ]
        }
      },
      fields: [
        {
          key: 'BUCHUNGSDATUM',
          label: this.$t('accounting.lang_bookingDate'),
          sortable: false,
          'class': 'text-center'
        },
        {
          key: 'BELEGDATUM',
          label: this.$t('accounting.lang_receiptDate'),
          sortable: false,
          'class': 'text-center'
        },
        {
          key: 'KONTO',
          label: this.$t('accounting.langAccountingAccount'),
          sortable: false,
          'class': 'text-center'
        },
        {key: 'EINNAHME', label: this.$t('accounting.lang_income'), sortable: false, 'class': 'text-center'},
        {key: 'AUSGABE', label: this.$t('accounting.lang_expense'), sortable: false, 'class': 'text-center'},
        {
          key: 'currentCash',
          label: this.$t('accounting.lang_currentCash'),
          sortable: false,
          'class': 'text-center'
        },
        {key: 'BELEGNR', label: this.$t('accounting.lang_invoiceNo'), sortable: false, 'class': 'text-center'},
        {key: 'STEUER', label: this.$t('accounting.lang_taxes'), sortable: false, 'class': 'text-center'},
        {key: 'AKTION', label: this.$t('generic.lang_action'), sortable: false, 'class': 'text-center'},

      ],
      items: [],
      cashBookLoading: false,
      cashierIDsVal: null,
      itemTaxSelect: [],
      cashierIDs: null,
      period: null,
      accounts: null,
      startCredit: null,
      endCredit: null,
      periodVal: null,
      cashbookActiveMonth: null,
      cashBookEntryID: null,
      cashierIDEditVal: null,
      editEntryType: 0,
      editEntryTax: null,
      editEntryAccount: null,
      editEntryBelNo: null,
      editEntryBelDate: null,
      editEntryFreetext: null,
      editEntryTotal: null,
      dialogEdit: false,
      showCreateEntryDialog: false,
      tseDevice: null,
      loadingXPrinting: false,
      hideSystemCountedCash: true,
      countingMandatory: true,
      cashbookTimestamp: 0
    }
  },

  watch: {
    countedEndCash(val) {
      this.checkNegativity(val, "countedEndCash")
    },
    showDialog(val) {
      if (!val) {
        //RESET DATA WHEN CLOSING DIALOG
        this.loading = false;
        this.loadingCashbookValue = false;
        this.hideSystemCountedCash = true;
        this.countingMandatory = true;
        this.usingCounting = true;
        this.countedEndCash = 0.00;
        this.countedEndCashOrigin = 0.00;

        Events.$off("eposDevice", this.eposDeviceCallback);
        Events.$off("GFE_OnReceive", this.GFE_OnReceiveCallback)
      } else {
        this.getCurrentShift();
        this.getCashFromCashBook();
        this.loadData();
        this.printXReport();
        this.step = 2;
        Events.$on("eposDevice", this.eposDeviceCallback);
        Events.$on("GFE_OnReceive", this.GFE_OnReceiveCallback);
      }
    },
    usingCounting(val) {
      if (val) {

      } else {
        this.lastFocus = null;
      }
    },
    step(val) {
      if (val > 2) {
        this.loadData()
      }
    }
  },

  computed: {
    ...mapState([
      'api',
      'pos',
      'user'
    ]),
    ...mapState('printer', {
      printers: state => state.printers
    }),
    ...mapGetters({
      fiscalClient: 'tse/fiscalClient',
      activeShiftUUID: 'pos/activeShiftUUID'
    }),
    disableCashbookEntries() {
      return Number(this.$store.getters['settings/getSettingValue']('disable_cashbook_end_shift')) > 0;
    },
    currencyIMG(){
      return this.$store.getters['settings/getSettingValue']('geoloc_systemcountry') === "ma"?"mad":"euro";
    },
    invoicePrinter() {
      return this.printers.find((printer) => {
        if (printer.cashierID.includes(this.api.auth.cashierID)) {
          if (printer.type === 1) {
            return true;
          }
        }
      });
    },
    total() {
      let somme = 0;
      this.currency[this.currencyIMG].muenzen.forEach(coin => {
        somme += coin.value * Number(coin.inValue);
      });
      this.currency[this.currencyIMG].scheine.forEach(paper => {
        somme += paper.value * Number(paper.inValue);
      });
      return somme;
    },
    validCountingInput(){
      if(!this.countingMandatory)
        return true;
      return this.total > 0;
    }
  },

  beforeDestroy() {
    Events.$off("eposDevice", this.eposDeviceCallback);
    Events.$off("GFE_OnReceive", this.GFE_OnReceiveCallback)
  },
  mounted() {
    this.cashierIDsVal = this.api.auth.cashierID;
  },
  methods: {
    checkNegativity(val, variable) {
      if (variable === 'editEntryTotal')
        if (Number(val) < 0) this.editEntryTotal = 0;

      if (variable === 'countedEndCash') {
        if (Number(val) <= 0) this.countedEndCash = -1 * this.countedEndCash;
      }
    },
    async loadDailyReportSettings() {
      return new Promise((resolve, reject) => {
        this.axios.post(ENDPOINTS.ACCOUNTING.DAILYREPORT.SETTINGS.GET).then((res) => {
          if (res.data.success) {
            this.open_cashDrawer = res.data.settings.open_cashDrawer;
            this.disable_print_interim_report = res.data.settings.disable_print_interim_report;
            this.hideSystemCountedCash = res.data.settings.hideSystemCountedCash;
            this.countingMandatory = res.data.settings.countingMandatory;
            this.usingCounting = this.countingMandatory;
          }
          this.loading = false;
          resolve(res);
        }).catch((err) => {
          Events.$emit("showSnackbar", {
            message: this.$t('generic.lang_errorOccurred'),
            color: "error"
          });
          console.log(err)
          reject(err);
        })
      })
    },
    openCashDrawer(status) {
      if (status) {
        let printData = [{
          cashDrawer: 1
        }];
        // EPSON EPOS PRINT
        printDataFromPrinter(this.invoicePrinter,printData).then(()=>{
          Events.$emit("showSnackbar", {
            message: this.$t("generic.lang_success"),
            color: "success"
          });
        }).catch((err)=>{
          Events.$emit("showSnackbar", {
            message: this.$t('generic.lang_errorOccurred'),
            color: "error"
          });
        }).finally(()=>{
          this.loading = false;
        })

      }
    },
    closeCreateEntryDialog() {
      this.showCreateEntryDialog = false;
      this.getCashFromCashBook();
      this.loadData();
    },
    loadData(filterEntries = false) {
      this.cashBookLoading = true;

      //CHECK IF SHOULD FILTER ENTRIES
      let getPayload = {};

      this.axios.post(ENDPOINTS.ACCOUNTING.CASHBOOK.GET, getPayload).then((result) => {
        let data = result.data;
        let items = [];
        let cashierIDsOptions = [];
        let periodOptions = [];
        let accountOptions = [];


        data.tax.forEach((row) => {
          this.itemTaxSelect.push({name: row[1], tax: parseFloat(row[0])});
        });

        cashierIDsOptions.push({text: this.api.auth.cashierID, value: this.api.auth.cashierID});

        this.cashierIDs = cashierIDsOptions;
        if (data.cashBookPeriod && data.cashBookPeriod != null && data.cashBookPeriod.length > 0) {
          for (let i = 0; i < data.cashBookPeriod.length; i++) {
            let item = data.cashBookPeriod[i];
            periodOptions.push({text: item[1], value: item[0]});
          }
        }
        this.period = periodOptions;


        if (data.accounts && data.accounts != null && data.accounts.length > 0) {
          for (let i = 0; i < data.accounts.length; i++) {
            let item = data.accounts[i];
            accountOptions.push({text: item[1], value: item[0]});
          }
        }
        this.accounts = accountOptions;

        this.startCredit = data.formfillData.additionalText.startBarbestand;
        this.endCredit = data.formfillData.additionalText.lfdBarbestand;
        this.periodVal = data.formfillData.additionalText.cashBookActivePeriod;
        this.cashbookActiveMonth = data.formfillData.additionalText.cashBookMonth;

        let itemOptions = [];
        if (data.cashBookEntriesArr && data.cashBookEntriesArr != null && data.cashBookEntriesArr.length > 0) {
          for (let i = 0; i < data.cashBookEntriesArr.length; i++) {
            let item = data.cashBookEntriesArr[i];
            itemOptions.push({
              ENTRYUUID: item[0],
              ENTRYID: item[1],
              BUCHUNGSDATUM: item[2],
              BELEGDATUM: item[3],
              KONTO: item[4],
              EINNAHME: item[5],
              AUSGABE: item[6],
              currentCash: item[7],
              BELEGNR: item[8],
              STEUER: item[9],
              CLOSED: item[10]
            });
          }
        }

        this.items = itemOptions;

      }).catch((err) => {
        Events.$emit("showSnackbar", {
          message: this.$t('generic.lang_errorOccurred') + ' ' + err.message,
          color: "error"
        });
      }).finally(() => {
        this.cashBookLoading = false;
      })
    },
    editCashbookEntry: function () {
      let self = this;
      this.axios.post(ENDPOINTS.ACCOUNTING.CASHBOOK.UPDATE, {
        cashBookIDEntry: this.cashBookEntryID,
        cashBookCashierIDEntry: this.cashierIDEditVal,
        buchungsBelegArt: this.editEntryType,
        buchungsSatzSteuer: this.editEntryTax,
        cashBookKontoNo: this.editEntryAccount,
        cashBookBelegRr: this.editEntryBelNo,
        Belegdatum: this.editEntryBelDate,
        cashBookNotes: this.editEntryFreetext,
        cashBookTotal: this.editEntryTotal,
      }).then((res) => {
        if (res.data.status === 'SUCCESS') {
          Events.$emit("showSnackbar", {
            message: this.$t('accounting.lang_cashbookEntryEdited'),
            color: "success"
          });
          self.loadData();
          this.getCashFromCashBook();
          this.dialogEdit = false;
        } else {
          Events.$emit("showSnackbar", {
            message: this.$t('generic.lang_errorOccurred'),
            color: "error"
          });
        }
      }).catch(() => {
        Events.$emit("showSnackbar", {
          message: this.$t('generic.lang_errorOccurred'),
          color: "error"
        });
      });
    },
    editEntryDialog: function (id) {

      let self = this;

      self.axios.post(ENDPOINTS.ACCOUNTING.CASHBOOK.GETENTRY, {
        cashBookEntryID: id
      }).then(function (result) {
        let data = result.data;
        let formfields = data.formfillData.textFields;

        self.cashBookEntryID = id;
        self.cashierIDEditVal = formfields.cashierID;
        self.editEntryType = formfields.belegart;
        self.editEntryTax = formfields.mwststeuer;
        self.editEntryAccount = formfields.accountID;
        self.editEntryBelNo = formfields.rechnungsnr;
        self.editEntryBelDate = formfields.optReceiptDateTimeStamp;
        self.editEntryFreetext = formfields.freitext;
        self.editEntryTotal = formfields.betragBrutto;

        self.dialogEdit = true;
      })


    },
    deleteEntry: function (id) {
      let self = this;
      this.$swal({
        title: this.$t('generic.lang_deletedEntryTitle'),
        text: this.$t('generic.lang_deletedCashBookEntryText'),
        icon: "warning",
        cancelButtonText: this.$t('generic.lang_cancel'),
        confirmButtonText: this.$t('generic.lang_delete'),
        showCancelButton: true,
        showLoaderOnConfirm: true,
        preConfirm: () => {

          this.axios.post(ENDPOINTS.ACCOUNTING.CASHBOOK.DELETE, {
            cashBookEntryID: id
          }).then((res) => {
            if (res.data.status === 'SUCCESS') {
              Events.$emit("showSnackbar", {
                message: this.$t('generic.lang_entryHasBeenDeleted'),
                color: "success"
              });
              self.loadData();
              this.getCashFromCashBook();
            } else {
              Events.$emit("showSnackbar", {
                message: this.$t('generic.lang_errorOccurred'),
                color: "error"
              });
            }
          });
        },
        allowOutsideClick: () => !this.$swal.isLoading()
      });
    },
    update(value = null) {
      //console.log(value)
      if (value !== null)
        if (this.lastFocus !== null) {
          this.lastFocus.inValue += '' + value;
        } else {
          this.countedEndCash = Number(this.countedEndCash) * 10;
          this.countedEndCash += Number(value) / 100;
        }

      if (this.usingCounting) {
        this.countedEndCash = this.total;
      }

    },
    remember(event, currency) {
      this.lastFocus = null;
      if (currency !== null) {
        this.lastFocus = currency;
      }
    },
    cancelDialog() {
      if (this.step > 2) {
        this.finishClosingShift(this.shiftUUID, true)
      } else {
        this.$emit("cancelDialog");
      }

    },
    closeDialog() {
      if (this.step > 3) {
        this.finishClosingShift(this.shiftUUID, true)
      } else {
        this.$emit("closeDialog");
      }

    },
    getCashFromCashBook() {
      this.loadingCashbookValue = true;

      this.axios.post(ENDPOINTS.POS.CASHBOOKVALUE, {
        cashierID: this.api.auth.cashierID
      }).then((res) => {
        if (res.data.success) {
          //    if (res.data.value > 0) {
          this.countedEndCash = res.data.value;
          this.countedEndCashOrigin = res.data.value;
          this.cashbookTimestamp = res.data.timestamp;
          // }
        }

        this.loadingCashbookValue = false;
      }).catch(() => {
        this.loadingCashbookValue = false;
      });
    },
    checkCloseShift(printReport = false) {
      this.loading = true;

      //CHECK IF SHIFT ID IS SET
      if (this.activeShiftUUID !== null) {
        if (this.activeShiftUUID.length > 0) {
          this.closeShift(printReport);

          return;
        }
      }

      this.axios.post(ENDPOINTS.POS.ACTIVESHIFT.GET, {
        cashierID: this.api.auth.cashierID
      }).then((res) => {
        if (res.data.success) {
          //ATTACH ACTIVE SHIFT
          this.$store.dispatch("pos/updateActiveShift", res.data.activeShift).then(() => {
            this.closeShift(printReport);
          });
          this.startCash = res.data.activeShift.countedStartCash;
        } else {
          this.errorMessage = res.data.msg;
          this.loading = false;
        }
      }).catch(() => {
        this.errorMessage = this.$t('generic.lang_errorOccurred');
        this.loading = false;
      });
    },
    closeShift(printReport = false) {
      this.loading = true;

      let countings = [];
      countings = this.currency[this.currencyIMG].muenzen.map(el => {
        return {
          value: el.value + '',
          qty: parseInt(Number(el.inValue))
        }
      }).concat(this.currency[this.currencyIMG].scheine.map(el => {
        return {
          value: el.value + '',
          qty: parseInt(Number(el.inValue)),
        }
      }))

      this.axios.post(ENDPOINTS.POS.ACTIVESHIFT.CLOSE, {
        cashierID: this.api.auth.cashierID,
        countedEndCash: this.countedEndCash,
        shiftUUID: this.activeShiftUUID,
        cashbookTimestamp: this.cashbookTimestamp,
        exportData: this.tseExportData,
        endCashCountingProtocol: (this.usingCounting) ? countings : null,
      }).then((res) => {
        if (res.data.success) {
          let shiftUUID = this.activeShiftUUID;
          this.cashbookEntryUUID = res.data.entryUUID;

          this.$store.dispatch("pos/updateActiveShift", {
            uuid: null
          }).then(() => {
            if (printReport) {
              //GET PRINTING DATA FROM API
              this.axios.post(ENDPOINTS.ACCOUNTING.DAILYREPORT.PRINTZ, {
                reportID: shiftUUID
              }).then((res) => {
                if (res.data.status === 'SUCCESS') {
                  this.shiftClosed(res, shiftUUID);
                } else {
                  this.loading = false;

                  this.finishClosingShift(shiftUUID);

                  Events.$emit("showSnackbar", {
                    message: this.$t('generic.lang_dailyClosingCouldNotBePrinted'),
                    color: "error"
                  });
                }
              }).catch(() => {
                this.loading = false;

                this.finishClosingShift(shiftUUID);

                Events.$emit("showSnackbar", {
                  message: this.$t('generic.lang_dailyClosingCouldNotBePrinted'),
                  color: "error"
                });
              });
            } else {
              this.checkECTerminal(shiftUUID);
            }
          });
        } else {
          this.errorMessage = res.data.msg;
          this.loading = false;
        }
      }).catch(() => {
        this.errorMessage = this.$t('generic.lang_shiftCouldNotBeCompleted');
        this.loading = false;
      })
    },
    printXReport() {
      this.loadDailyReportSettings().then((res1) => {
        if (res1.data.success) {
          this.open_cashDrawer = res1.data.settings.open_cashDrawer;
          this.disable_print_interim_report = res1.data.settings.disable_print_interim_report;

          this.openCashDrawer(res1.data.settings.open_cashDrawer);

          if (res1.data.settings.disable_print_interim_report === 0) {
            this.loadingXPrinting = true;
            //CHECK IF SHIFT ID IS SET
            if (this.activeShiftUUID === null) {
              return;
            }

            this.axios.post(ENDPOINTS.ACCOUNTING.DAILYREPORT.PRINTX, {
              reportType: "X",
              reportID: this.activeShiftUUID
            }).then((res) => {
              if (res.data.status === 'SUCCESS') {
                printDataFromPrinter(this.invoicePrinter,res.data.printXML).then(()=>{
                }).catch((err)=>{
                  Events.$emit("showSnackbar", {
                    message: this.$t('accounting.lang_xClosingCouldNotBePrinted') + ": " + err,
                    color: "error"
                  });
                }).finally(()=>{
                  this.loadingXPrinting = false;
                })

              } else {
                this.loadingXPrinting = false;

                Events.$emit("showSnackbar", {
                  message: this.$t('accounting.lang_xClosingCouldNotBeCreated'),
                  color: "error"
                });
              }
            }).catch((err) => {
              console.log(err)
              this.loadingXPrinting = false;
              Events.$emit("showSnackbar", {
                message: this.$t('accounting.lang_xClosingCouldNotBePrinted'),
                color: "error"
              });
            });
          }
        }
        this.loading = false;
      }).catch((err) => {
        console.log(err)
        Events.$emit("showSnackbar", {
          message: this.$t('generic.lang_errorOccurred'),
          color: "error"
        });
      })

    },
    shiftClosed(res, shiftUUID) {
      printDataFromPrinter(this.invoicePrinter,res.data.printXML).then(()=>{
        this.checkECTerminal(shiftUUID);
      }).catch((err)=>{
        this.checkECTerminal(shiftUUID);

        Events.$emit("showSnackbar", {
          message: this.$t('accounting.lang_dailyClosingCouldNotBePrinted'),
          color: "error"
        });
      }).finally(()=>{

        this.loading = false;
      })
    },
    checkECTerminal(shiftUUID) {
      this.axios.post(ENDPOINTS.POS.ACTIVEECTERMINAL).then((res) => {
        if (res.data.success) {
          let ecTerminal = res.data.ecTerminal;

          //CHECK IF SHOULD AUTO PRINT EC TERMINAL DAILY REPORT
          if (!ecTerminal.printDailyReport) {
            this.checkTSE(shiftUUID);
            return;
          }

          this.loading = true;

          //CREATE NEW AXIOS INSTANCE
          let zvtAxios = this.axios.create({
            baseURL: 'http://' + ecTerminal.zvtServerIP,
            timeout: 60000000,
            headers: {
              'terminal-id': ecTerminal.id,
              'terminal-ip': ecTerminal.terminalIP,
              'terminal-port': ecTerminal.terminalPort,
              'terminal-password': ecTerminal.terminalPW,
              'terminal-ecr': (ecTerminal.printOnECR !== 0),
            }
          });

          //MAKE REQUEST
          zvtAxios.post('/daily-report').then((res) => {
            if (res.status === 200) {
              this.loading = false;
              //PREPARE PRINTING
              let print = [];

              res.data.printing.forEach((item) => {
                print.push({text: item});
              });

              //ADD FINAL CUT
              print.push({cut: true});

              //START PRINTING
              printDataFromPrinter(this.invoicePrinter,print).then(()=>{
                this.checkTSE(shiftUUID);
              }).catch((err)=>{
                this.checkTSE(shiftUUID);
              }).finally(()=>{
                this.loading = false;
              })
            } else {
              this.loading = false;
              this.checkTSE(shiftUUID);
            }
          }).catch(() => {
            this.checkTSE(shiftUUID);
          });
        } else {
          this.checkTSE(shiftUUID);
        }
      }).catch(() => {
        this.checkTSE(shiftUUID);
      });
    },
    checkTSE(shiftUUID) {
      this.shiftUUID = shiftUUID;

      //CHECK IF TSE CLIENT IS SETUP
      if (this.fiscalClient === null) {
        this.finishClosingShift();
        return;
      }

      if (this.fiscalClient.device.type === null) {
        this.fiscalClient.device.type = "epsonTSE";
      }
      //FISKALY CLOUD TSE
      if (this.fiscalClient.device.type === "fiskaly") {
        this.exportTSEData(shiftUUID);
        this.finishClosingShift();
        return;
      }

      //CHECK IF TSE PRINTER EXISTS
      let tseDevices = this.$eposClass.getTSEPrinters();

      if (!tseDevices.hasOwnProperty(this.fiscalClient.id)) {
        //ADD TSE
        this.$eposClass.addTSEPrinter(new this.$epson.ePOSDevice(), {
          id: this.fiscalClient.id,
          ip: this.fiscalClient.device.ip,
          TSEProxyIPAdress: this.fiscalClient.device.TSEProxyIPAdress,
          port: this.fiscalClient.device.port,
          deviceID: this.fiscalClient.device.deviceID,
          adminID: 'Administrator',
          clientID: this.fiscalClient.clientID,
        }, false, false, this.fiscalClient.device.useTSEProxy === 1);
      }

      this.tseDevice = this.$eposClass.getTSEPrinter(this.fiscalClient.id);

      if (this.tseDevice?.tseReady) {
        this.startTransaction();
      } else {
        if (this.tseDevice.connected) {
          //TSE WAS ADDED BUT ISNT READY YET
          this.errorMessage = this.$t('generic.lang_tseIsNotReadyTryAgain');
        } else {
          //TRY TO RE-CONNECT!!!
          this.tseDevice.connect();
        }
      }
    },
    // -----------------------------------------------
    // ------------------ GFE / TSE ------------------
    // ------------------ CALLBACKS ------------------
    // -----------------------------------------------
    eposDeviceCallback(payload) {
      if (this.showTSEExportDialog)
        return;

      if (payload.action === "createDeviceCallback.error") {
        this.errorMessage = `${this.$t('generic.lang_setupOfTheTseCouldNotBeStarted')}<br>(${payload.code})`;
      }

      if (payload.action === "connectCallback.error") {
        //CONNECTION ERROR
        this.errorMessage = this.$t('generic.lang_connectionToTseCouldNotBeEstablishedCheckIp')
      }

      if (payload.action === "disconnect") {
        this.errorMessage = this.$t('generic.lang_connectionToTseLost');
      }
    },
    GFE_OnReceiveCallback(payload) {
      if (this.showTSEExportDialog)
        return;

      if (payload.result.function !== "StartTransaction") {
        if (payload.result.function === "GetStorageInfo") {
          this.startTransaction();
        } else {
          if (payload.result.result !== "EXECUTION_OK") {
            //OTHER ERROR OCCURED -> ABORTING
            this.errorMessage = `${this.$t('generic.lang_errorOccurred')} (${payload.result.result})`;
          }
        }
      }
    },
    startTransaction() {
      this.tseDevice?.GFE_StartTransaction(this.fiscalClient.clientID);

      Events.$once("GFE_OnReceive", (payload) => {
        if (payload.result.function === "StartTransaction") {
          if (payload.result.result === "EXECUTION_OK") {
            this.finishTransaction(payload.result.output, this.shiftUUID);
          } else {
            this.errorMessage = this.$t('generic.lang_couldNotCompleteSigning') + ": " + payload.result.result;
          }
        }
      });
    },
    finishTransaction(transactionPayload, shiftUUID) {
      let changedValue = (this.countedEndCash - this.countedEndCashOrigin).toFixed(2);

      //CREATE FAKE ITEM TO USE EXISTING FUNCTION
      let items = [{
        isVoid: false,
        sellPrice: changedValue,
        taxValue: 0,
        amount: 1,
      }];

      this.tseDevice.finishInvoiceTransaction(this.fiscalClient.clientID, transactionPayload, items, 1, 1, []);

      Events.$once("GFE_OnReceive", (payload) => {
        if (payload.result.function === "FinishTransaction") {
          if (payload.result.result === "EXECUTION_OK") {
            //SAVE FISCAL DATA
            this.saveFiscalData(transactionPayload, payload.result.output, shiftUUID);

            this.exportTSEData(shiftUUID);
          } else {
            this.errorMessage = this.$t('generic.lang_couldNotCompleteSigning') + ": " + payload.result.result;
          }
        }
      });
    },
    saveFiscalData(startTransactionResultOutput = null, finishTransactionResultOutput = null, shiftUUID) {
      //CHECK IF ENTRY WAS CREATED
      if (this.cashbookEntryUUID === null)
        return;

      //SAVE FISCAL DATA INTO DATABASE
      if (startTransactionResultOutput !== null && finishTransactionResultOutput !== null) {
        let payload = {
          fiscalDevice: {
            id: this.fiscalClient.device.id,
            deviceID: this.fiscalClient.device.deviceID
          },
          fiscalClient: {
            id: this.fiscalClient.id,
            clientID: this.fiscalClient.clientID
          },
          tse: {
            serialNumber: this.tseDevice.storageInfo.tseInformation.serialNumber,
          },
          transaction: {
            processType: "Kassenbeleg-V1",
            startTime: startTransactionResultOutput.logTime,
            stopTime: finishTransactionResultOutput.logTime,
            transactionNumber: startTransactionResultOutput.transactionNumber,
            signatureCounter: finishTransactionResultOutput.signatureCounter,
            signature: finishTransactionResultOutput.signature
          }
        };

        //SAVE FISCAL DATA INTO DATABASE
        this.axios.post(ENDPOINTS.ACCOUNTING.CASHBOOK.UPDATETSEDATA, {
          entryUUID: this.cashbookEntryUUID,
          tsePayload: payload
        }).then(() => {

        }).catch(() => {

        });
      }
    },
    exportTSEData(shiftUUID) {
      this.showTSEExportDialog = true;
      this.$refs.tseExportDialog.checkTSE(shiftUUID);
    },
    finishClosingShift(shiftUUID = "", finished = false) {
      this.showTSEExportDialog = false;
      if (this.dontRedirect && !finished) {
        this.$emit("shiftClosed", shiftUUID);
        this.errorMessag = '';
        this.step = 4;
      } else {
        this.$router.replace({
          name: "dashboard",
        });
      }
    },
    del() {
      if (this.usingCounting) {

        this.currency[this.currencyIMG].muenzen.forEach(coin => {
          coin.inValue = '';
        });
        this.currency[this.currencyIMG].scheine.forEach(paper => {
          paper.inValue = '';
        });

        this.countedEndCash = this.total;

      }

      if (this.lastFocus === null) {
        this.countedEndCash = '';
      }
    },
    getCurrentShift() {
      this.loading = true;

      this.axios.post(ENDPOINTS.POS.ACTIVESHIFT.GET, {
        cashierID: this.api.auth.cashierID
      }).then((res) => {
        if (res.data.success) {
          this.$store.dispatch("pos/updateActiveShift", res.data.activeShift)
          //ATTACH ACTIVE SHIFT
          this.startCash = res.data.activeShift.countedStartCash;
        } else {
          this.errorMessage = res.data.msg;
        }
      }).catch(() => {
        this.handleShiftError();
      }).finally(() => {
        this.loading = false;
      });
    },
    closeErrorDialog() {
      this.errorMessage = "";

      if (this.shiftUUID !== null)
        this.finishClosingShift(this.shiftUUID, false);
      else
        this.finishClosingShift("", false);
    },
    handleShiftError(){
      this.$swal({
                        title:this.$t('generic.lang_startShift'),
                        text: this.$t('generic.lang_thereIsNetwork_internetProblem'),
                        icon: "error",
                        showLoaderOnDeny: true,
                        showDenyButton: true,
                        denyButtonText: this.$t('generic.lang_prev'),
                        confirmButtonText: this.$t('generic.lang_refreshPage'),
                        showLoaderOnConfirm: true,
                        preConfirm: () => {
                          window.location.reload();
                        },
                        preDeny: () =>{
                            this.$router.replace('/dashboards/analytics')
                        },
                        allowOutsideClick: () => false,
                    });
    },
  },
}
</script>

<style scoped>
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
</style>
